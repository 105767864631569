exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-best-psychics-page-slug-js": () => import("./../../../src/pages/{BestPsychicsPage.slug}.js" /* webpackChunkName: "component---src-pages-best-psychics-page-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-reviews-site-review-page-slug-js": () => import("./../../../src/pages/reviews/{SiteReviewPage.slug}.js" /* webpackChunkName: "component---src-pages-reviews-site-review-page-slug-js" */),
  "slice---src-components-ad-modal-js": () => import("./../../../src/components/adModal.js" /* webpackChunkName: "slice---src-components-ad-modal-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

